import React from "react"
import tw from "twin.macro"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import StaticHeroIh from "@components/staticHeroIh"
import Section from "@components/section"
import Container from "@components/container"
import Quiz from "@components/quiz/ih/Quiz"
import quizData from "@components/quiz/ih/data"
import InlineVideoSectionIhRedesign from "@components/inlineVideoSectionIhRedesign"
import InlineVideo from "@components/inlineVideo"
import PrimaryCta from "@components/PrimaryCta"
import SecondaryCallout from "@components/SecondaryCallout"
import StayConnectedBannerIh from "@components/StayConnectedBannerIh"

import { sectionIh, primaryCtaIh } from "@styles/ui"

import { RECAPTCHA_3_KEY } from "@utils/constants"

const YourIHExperience = ({ location, data }) => {
  return (
    <Layout location={location}>
      <Seo
        title="Managing the Burden Of Idiopathic Hypersomnia (IH) | XYWAV® IH"
        description="Living with Idiopathic Hypersomnia may be difficult to manage. Learn how XYWAV® (calcium, magnesium, potassium, and sodium oxybates) may be able to help with your symptoms. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <StaticHeroIh
        variant="asleep"
        heading={<>What&apos;s your Idiopathic Hypersomnia (IH) experience?</>}
        subheading="Understanding your symptoms can help your doctor treat your IH"
      />
      <Section addedStyles={sectionIh}>
        <Container isIh>
          <div id="quiz" tw="lg:scroll-mt-20">
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_3_KEY}>
              <Quiz quizData={quizData} pageId={"your-ih-experience"} />
            </GoogleReCaptchaProvider>
          </div>
        </Container>
      </Section>

      <InlineVideoSectionIhRedesign
        isIh={true}
        bgColour={tw`bg-lilac`}
        video={
          <InlineVideo
            wistiaId="zoj62xybuo"
            thumbnail={data.thumbImage}
            alt="Watch the video on tips for talking to your doctor about XYWAV®"
            inlineIh={true}
          />
        }
      >
        <h2 tw="text-2xl text-studio text-left font-ffcocon mb-4 lg:(text-4xl mb-6)">
          &ldquo;It was very frustrating until I could have a doctor who was a
          specialist in this say, &lsquo;Yes this is an issue, this is a real
          condition.&rsquo;&rdquo;
        </h2>
        <p tw="text-base text-left font-light mb-4 lg:(mb-8 max-w-[30rem])">
          IH Ambassador Diana and her doctor guide you through a conversation
          about IH symptoms and XYWAV.
        </p>
        <PrimaryCta
          url="/idiopathic-hypersomnia/dosing/"
          addedStyles={[primaryCtaIh, tw`capitalize w-fit`]}
        >
          Prepare for your conversation
        </PrimaryCta>
      </InlineVideoSectionIhRedesign>

      <Section
        bgColour={tw`bg-lilac`}
        addedStyles={[sectionIh, tw`pt-0 lg:(pt-0)`]}
      >
        <Container isIh>
          <SecondaryCallout
            title={
              <span tw="block max-w-[37.375rem]">
                If you&apos;re trying to manage your IH, but still living with
                symptoms, it may be time to talk to a provider about XYWAV.
              </span>
            }
            cta="Find a provider near you"
            url="/idiopathic-hypersomnia/find-a-doctor/"
          />
        </Container>
      </Section>

      <StayConnectedBannerIh />
    </Layout>
  )
}

export default YourIHExperience

export const query = graphql`
  query {
    thumbImage: file(
      relativePath: { eq: "ih/your-ih-experience/xywav-patient-diana-tips.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
  }
`
